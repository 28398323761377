import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Pagination as BSPagination } from "react-bootstrap";

const PaginationStyled = styled(BSPagination)`
  .page-item {
    margin: 0 5px;
  }
  .page-link {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    color: ${({
  theme
}: any) => theme.colors.dark} !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus {
      z-index: 2;
      color: #fff !important;
      text-decoration: none;
      background-color: ${({
  theme
}: any) => theme.colors.secondary};
      border-color: ${({
  theme
}: any) => theme.colors.secondary};
      box-shadow: none;
    }
  }
`;

const Pagination = (props: any) => <>
  <PaginationStyled {...props} />
</>;

export default Pagination;

export const PageItem = (props: any) => <>
  <PaginationStyled.Item {...props} />
</>;
