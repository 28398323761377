import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section, Box, Badge } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { rgba } from "polished";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB1 from "../../assets/image/jpeg/blog-post--img.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB2 from "../../assets/image/jpeg/blog-post--img-2.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB3 from "../../assets/image/jpeg/blog-post--img-3.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB4 from "../../assets/image/jpeg/blog-post--img-4.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB5 from "../../assets/image/jpeg/blog-post--img-5.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB6 from "../../assets/image/jpeg/blog-post--img-6.jpg";

const BadgeStyled = styled(Badge)`
  background-color: ${({
  theme,
  bg
}: any) => rgba(theme.colors[bg], 0.15)};
  color: ${({
  theme,
  bg
}: any) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`;

const BlogCategories = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="4" className="mb-5">
            <BadgeStyled bg={"secondary"}>Carports</BadgeStyled>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default BlogCategories;
