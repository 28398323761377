import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section, Box, Badge, Text, Title } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { rgba } from "polished";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB1 from "../../assets/image/jpeg/blog-post--img.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB2 from "../../assets/image/jpeg/blog-post--img-2.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB3 from "../../assets/image/jpeg/blog-post--img-3.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB4 from "../../assets/image/jpeg/blog-post--img-4.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB5 from "../../assets/image/jpeg/blog-post--img-5.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB6 from "../../assets/image/jpeg/blog-post--img-6.jpg";
import { breakpoints, device } from "../../utils";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Slider from "react-slick";

const BadgeStyled = styled(Badge)`
  background-color: ${({
  theme,
  bg
}: any) => rgba(theme.colors[bg], 0.15)};
  color: ${({
  theme,
  bg
}: any) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`;

const SectionStyled = styled(Section)``;

const SliderStyled = styled(Slider)`
  position: relative;

  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 65px;
    display: flex;
    width: 65px;
    background: ${({
  theme
}: any) => theme.colors.secondary};
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      transition: 0.4s;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: "\f060";
    }
    @media ${device.md} {
      /* margin-left: 60px; */
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
    transform: translate(50%, -50%);
    &:before {
      content: "\f061";
    }
    @media ${device.md} {
      /* margin-right: 60px; */
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background: ${({
  theme
}: any) => theme.colors.light}!important;
    color: ${({
  theme
}: any) => theme.colors.secondary}!important;
    &:before {
      color: ${({
  theme
}: any) => theme.colors.secondary}!important;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const SliderCard = styled(Box)`
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  box-shadow: ${({
  theme
}: any) =>
    `0 12px 34px -11px ${rgba(theme.colors.shadow, 0.125)}`};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
  @media ${device.sm} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media ${device.md} {
    padding-right: 50px;
    padding-left: 50px;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.lg} {
    margin: 60px 0 100px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media ${device.xl} {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const NumberBlock = styled(Box)`
  min-width: 115px;
  /* height: 213px; */
  border-radius: 8px;
  background-color: #eae9f2;
  text-align: center;
  margin: 0 auto;
  @media ${device.md} {
    /* margin-left: 0; */
  }
  span {
    color: ${({
  theme
}: any) => theme.colors.ash};
    font-size: 140px;
    font-weight: 900;
    letter-spacing: -4.38px;
  }
`;

const SliderText = styled(Box)`
  display: flex;
  flex-direction: column;

  flex: auto;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  @media ${device.sm} {
    /* padding-left: 60px; */
    /* padding-right: 0; */
  }
  @media ${device.md} {
    /* padding-left: 85px; */
  }
  @media ${device.lg} {
    /* padding-left: 30px; */
  }
`;

const BlogLatest = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: breakpoints.sm,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <SectionStyled pb={["0px!important"]}>
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg="10">
              <div className="text-center">
                <Title>Check our latest posts</Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
              <SliderStyled {...slickSettings}>
                <SliderItem>
                  <Col lg="12" className="mb-5">
                    <PostCard
                      img={imgB1}
                      preTitle="Nov 14, 2020"
                      title="How did we get 1M+ visitors in 30 days without anything!"
                      readMore
                    >
                      Throwing consider dwelling bachelor joy her proposal
                      laughter. Raptures returned disposed one entirely her men
                      ham.
                    </PostCard>
                  </Col>
                </SliderItem>
                <SliderItem>
                  <Col lg="12" className="mb-5">
                    <PostCard
                      img={imgB1}
                      preTitle="Nov 14, 2020"
                      title="How did we get 1M+ visitors in 30 days without anything!"
                      readMore
                    >
                      Throwing consider dwelling bachelor joy her proposal
                      laughter. Raptures returned disposed one entirely her men
                      ham.
                    </PostCard>
                  </Col>
                </SliderItem>
                <SliderItem>
                  <Col lg="12" className="mb-5">
                    <PostCard
                      img={imgB1}
                      preTitle="Nov 14, 2020"
                      title="How did we get 1M+ visitors in 30 days without anything!"
                      readMore
                    >
                      Throwing consider dwelling bachelor joy her proposal
                      laughter. Raptures returned disposed one entirely her men
                      ham.
                    </PostCard>
                  </Col>
                </SliderItem>
              </SliderStyled>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default BlogLatest;
