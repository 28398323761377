import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text, Span, Badge } from "../Core";
import { device } from "../../utils";
import { rgba } from "polished";

const Card = styled(Box)`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  overflow: hidden;
  background-color: #fff;
  &:hover {
    box-shadow: ${({
  theme
}: any) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const ImageContainerHorizontal = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`;

const BrandImage = styled(Box)`
  position: absolute;
  bottom: 28px;
  left: 30px;
  border-radius: 8px;
  border: 1px solid #eae9f2;
  overflow: hidden;
`;

const CardText = styled(Box)`
  padding: 30px;
`;

const TitleStyled = styled(Title)`
  transition: 0.3s;
  &:hover {
    color: ${({
  theme
}: any) => theme.colors.secondary};
  }
`;

const BadgeStyled = styled(Badge)`
  background-color: ${({
  theme,
  bg
}: any) => rgba(theme.colors[bg], 0.15)};
  color: ${({
  theme,
  bg
}: any) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`;

const Tags = styled(Box)`
  span {
    margin-right: 10px;
  }
`;

const PostCard = ({
  horizontal = false,
  img,
  imgBrand,
  preTitle,
  title,
  children,
  readMore,
  tags,
  postPath,
  ...rest
}: any) => (
  <Card
    className={horizontal ? "d-flex flex-column flex-md-row" : ""}
    {...rest}
  >
    {horizontal ? (
      <ImageContainerHorizontal>
        <Link to="/" className="w-100 h-100 d-flex">
          <img src={img} alt="" className="w-100 img-fluid" />
          {imgBrand && (
            <BrandImage>
              <img src={imgBrand} alt="" className="img-fluid" />
            </BrandImage>
          )}
        </Link>
      </ImageContainerHorizontal>
    ) : (
      <Box className="position-relative">
        <Link to="/" className="w-100 h-100 d-flex">
          <img src={img} alt="" className="w-100 img-fluid" />
          {imgBrand && (
            <BrandImage>
              <img src={imgBrand} alt="" className="img-fluid" />
            </BrandImage>
          )}
        </Link>
      </Box>
    )}

    <CardText>
      {preTitle && (
        <Text fontSize={2} lineHeight={1.75} mb="14px">
          Jan 14, 2020
        </Text>
      )}

      <Link to={postPath}>
        <TitleStyled variant="card" mb="14px">
          {title}
        </TitleStyled>
      </Link>
      <Text fontSize={2} lineHeight={1.75} mb="16px">
        {children}
      </Text>
      {readMore && (
        <Box>
          <Link to={postPath}>
            <Span color="primary">Continue reading</Span>
          </Link>
        </Box>
      )}
      {tags && (
        <Tags mt="2">
          {tags.map((tag: any) => {
            return <BadgeStyled bg={"secondary"}>#{tag}</BadgeStyled>;
          })}
        </Tags>
      )}
    </CardText>
  </Card>
);

export default PostCard;
