import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section, Box, Title } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB1 from "../../assets/image/jpeg/blog-post--img.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB2 from "../../assets/image/jpeg/blog-post--img-2.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB3 from "../../assets/image/jpeg/blog-post--img-3.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB4 from "../../assets/image/jpeg/blog-post--img-4.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB5 from "../../assets/image/jpeg/blog-post--img-5.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/blog-p... Remove this comment to see the full error message
import imgB6 from "../../assets/image/jpeg/blog-post--img-6.jpg";

const BlogList = ({
  posts
}: any) => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <div className="text-center">
              <Title>All posts</Title>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          {posts &&
            posts.map((post: any) => {
              return (
                <Col lg="4" className="mb-5">
                  <PostCard
                    img={post.thumb.url}
                    preTitle="Nov 14, 2020"
                    title={post.title}
                    readMore={post.continue}
                    postPath={post.slug}
                  >
                    {post.description}
                  </PostCard>
                </Col>
              );
            })}
        </Row>
        <Box className="d-flex justify-content-center" mt={4}>
          <Pagination>
            <PageItem>
              <FaAngleLeft />
            </PageItem>
            <PageItem>1</PageItem>
            <PageItem>2</PageItem>
            <PageItem>3</PageItem>
            <PageItem>...</PageItem>
            <PageItem>9</PageItem>
            <PageItem>
              <FaAngleRight />
            </PageItem>
          </Pagination>
        </Box>
      </Container>
    </Section>
  </>
);

export default BlogList;
